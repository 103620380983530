import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Box, useTheme, useMediaQuery } from "@material-ui/core"
import Logo from "../Logo"
import { Link } from "gatsby-theme-material-ui"

const baseLinkStyles = theme => ({
  fontSize: 22,
  color: "#707070",
  // textTransform: "uppercase",
  margin: theme.spacing(0, 2),
  padding: theme.spacing(1),
  borderRadius: 4,
  "&:hover": {
    textDecoration: "none",
    backgroundColor: "#e9e9e9",
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
  },
  link: baseLinkStyles(theme),
  activeLink: {
    ...baseLinkStyles(theme),
    fontWeight: "bold",
    color: "#1C2541",
  },
}))

const TopNav = ({ menuItems }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  /**
   * utility function used to check
   * if a menu item is active or not
   * @param {*} url
   */
  const checkActive = (url, exact) => {
    if (typeof window !== `undefined`) {
      if (exact) {
        if (window.location.pathname === url) {
          return true
        }
        return false
      } else {
        if (window.location.pathname.includes(url)) {
          return true
        }
        return false
      }
    }
    return false
  }

  /**
   * Assign appropriate class name to menu item based
   * on if menu item is active or not
   * @param {*} url
   */
  const handleActive = (url, exact) => {
    const active = checkActive(url, exact)
    if (active) {
      return classes.activeLink
    }
    return classes.link
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link to="/">
            <Logo />
          </Link>
          {!mobile && (
            <nav>
              {menuItems.map(item => (
                <Link
                  key={item.id}
                  to={item.path}
                  className={handleActive(item.activePath, item.exact)}
                >
                  {item.title}
                </Link>
              ))}
            </nav>
          )}
        </Box>
      </Container>
    </div>
  )
}

TopNav.propTypes = {
  /**
   * An array of navigation menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      activePath: PropTypes.string.isRequired,
      exact: PropTypes.boolean,
    })
  ).isRequired,
}

export default TopNav
