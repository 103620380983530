import React from "react"
import PropTypes from "prop-types"
import { Box, Container, useTheme, useMediaQuery } from "@material-ui/core"

/**
 * Extends the functionality of Material UIs Box and
 * Container components
 * A common use case for this component is a home page
 * section such as the Header, Features, About Us, etc
 * sections
 */
const SectionContainer = ({ children, maxWidth, ...other }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  const padding = mobile ? 4 : 8

  return (
    <Box pt={padding} pb={padding} {...other}>
      {maxWidth ? (
        <Container maxWidth={maxWidth}>{children}</Container>
      ) : (
        <>{children}</>
      )}
    </Box>
  )
}

SectionContainer.propTypes = {
  maxWidth: PropTypes.string,
}

export default SectionContainer
