import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 400,
    color: props => props.color,
    textAlign: props => props.align,
  },
}))

const SectionText = ({ children, color = "inherit", align = "left" }) => {
  const classes = useStyles({ color, align })
  const paragraphs = children.split("\n\n")
  return (
    <div className={classes.root}>
      {paragraphs.map(p => (
        <Typography variant="body1" className={classes.text} paragraph>
          {p}
        </Typography>
      ))}
    </div>
  )
}

SectionText.propTypes = {
  align: PropTypes.string,
  color: PropTypes.string,
}

export default SectionText
