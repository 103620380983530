import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  default: {
    fontSize: 40,
    lineHeight: 1.3,
    fontWeight: "bold",
    color: props => props.color,
    textAlign: props => props.align,
  },
  alternate: {
    fontFamily: "bc-alphapipe, sans-serif",
    fontSize: 40,
    lineHeight: 1.3,
    color: props => props.color,
    textAlign: props => props.align,
  },
}))

const SectionTitle = ({
  children,
  variant = "default",
  color = "#444444",
  align = "left",
}) => {
  const classes = useStyles({ color, align })
  return (
    <Typography variant="h3" className={classes[variant]} gutterBottom>
      {children}
    </Typography>
  )
}

export default SectionTitle
