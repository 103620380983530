import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField, Box, Button } from "@material-ui/core"
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"

const useStyles = makeStyles(theme => ({
  smallTextField: {
    width: "49%",
    margin: theme.spacing(1, 0),
  },
  textField: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}))

const ContactUs = props => {
  const classes = useStyles()
  const [formValues, setFormValues] = useState({
    name: "",
    organization: "",
    email: "",
    message: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues(prevState => {
      let newValues = { ...prevState }
      newValues[name] = value
      return newValues
    })
  }

  return (
    <div className={classes.root}>
      <SectionTitle align="center" variant="alternate">
        Contact Us
      </SectionTitle>
      <SectionText align="center">
        We would love to hear from you! Use the form below to drop us a line or
        use the Schedule Meeting button to setup a call.
      </SectionText>
      <Box mt={3} mb={3}>
        <form
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="contact-us"
        >
          <input type="hidden" name="form-name" value="contact-us" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="center"
          >
            <TextField
              required
              name="name"
              value={formValues.name}
              placeholder="Name"
              label="Name"
              variant="filled"
              onChange={handleChange}
              color="secondary"
              className={classes.textField}
            />
            <TextField
              required
              name="organization"
              value={formValues.organization}
              placeholder="Organization"
              label="Organization"
              variant="filled"
              color="secondary"
              onChange={handleChange}
              className={classes.textField}
            />
            <TextField
              required
              name="email"
              value={formValues.email}
              placeholder="Email Address"
              label="Email Address"
              variant="filled"
              color="secondary"
              onChange={handleChange}
              className={classes.textField}
            />
            <TextField
              required
              multiline
              rows={5}
              name="message"
              value={formValues.message}
              placeholder="Message"
              label="Message"
              variant="filled"
              color="secondary"
              onChange={handleChange}
              className={classes.textField}
            />
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{ color: "#ffffff" }}
                disableElevation
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ color: "#ffffff", marginLeft: 8 }}
                disableElevation
                href={process.env.GATSBY_APP_CALENDLY_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Schedule a Demo
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default ContactUs
