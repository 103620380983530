import React, { useState, useReducer } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Box,
} from "@material-ui/core"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Emoji from "../Emoji/Emoji"

const useStyles = makeStyles(theme => ({
  btn: {
    color: "#ffffff",
    // fontWeight: "bold",
  },
  dialogContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  dailogText: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: "#edefff",
    borderRadius: 4,
  },
  textField: {
    width: "100%",
    margin: theme.spacing(1, 0),
  },
}))

const formReducer = (state, action) => {
  switch (action.type) {
    case "hidden":
      return {
        visible: false,
        message: "",
        state: "default",
      }
    case "success":
      return {
        visible: true,
        message:
          "Welcome aboard! Look for an email from us shortly. In the meantime, feel free to keep exploring the site.",
        state: "success",
      }
    case "error":
      return {
        visible: true,
        message:
          "Shoot! We had an issue getting you signed up. Please check that all fields are completed and that you entered a valid email address.",
        state: "error",
      }
    default:
      throw new Error()
  }
}

const initialState = {
  visible: false,
  message: "",
  state: "default",
}

const RequestEarlyAccess = ({ size = "medium" }) => {
  const classes = useStyles()
  const [formStatus, dispatch] = useReducer(formReducer, initialState)
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState({
    name: "",
    organization: "",
    email: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues(prevState => {
      let newValues = { ...prevState }
      newValues[name] = value
      return newValues
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const data = await await addToMailchimp(formValues.email, {
        NAME: formValues.name,
        ORG: formValues.organization,
      })

      if (data.result === "error") {
        dispatch({ type: "error" })
      } else {
        dispatch({ type: "success" })

        setTimeout(() => {
          dispatch({ type: "hidden" })
          setFormValues({
            name: "",
            organization: "",
            email: "",
          })
          setOpen(false)
        }, 5000)
      }
    } catch (err) {
      console.error(err)
      dispatch({ type: "error", message: err })
    }
  }

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        size={size}
        className={classes.btn}
        onClick={() => setOpen(true)}
      >
        Request Early Access
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h4" align="center" gutterBottom>
            <Emoji symbol="⚡" />
            Request Early Access
          </Typography>
          <Box mt={3} mb={3}>
            <form method="post" onSubmit={handleSubmit}>
              <TextField
                required
                name="name"
                value={formValues.name}
                placeholder="Name"
                label="Name"
                variant="filled"
                onChange={handleChange}
                color="secondary"
                className={classes.textField}
              />
              <TextField
                required
                name="organization"
                value={formValues.organization}
                placeholder="Organization"
                label="Organization"
                variant="filled"
                color="secondary"
                onChange={handleChange}
                className={classes.textField}
              />
              <TextField
                required
                name="email"
                value={formValues.email}
                placeholder="Email Address"
                label="Email Address"
                variant="filled"
                color="secondary"
                onChange={handleChange}
                className={classes.textField}
              />
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  size="large"
                  style={{ marginLeft: 8 }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </form>
            {formStatus.visible && (
              <Box
                bgcolor={formStatus.state === "success" ? "#edefff" : "#ffdde8"}
                padding={2}
                mt={2}
              >
                <Typography variant="body1">{formStatus.message}</Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

RequestEarlyAccess.propTypes = {
  size: PropTypes.string,
}

export default RequestEarlyAccess
