import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import SEO from "../seo"

const useStyles = makeStyles(theme => ({
  root: {},
}))

const HomeLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SEO title="Page two" />
      {children}
    </div>
  )
}

export default HomeLayout
