import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import GreyLogo from "../GreyLogo"
import LRELogo from "../LRELogo"
import { Link } from "gatsby-theme-material-ui"
import RequestEarlyAccess from "../RequestEarlyAccess/RequestEarlyAccess"

const useStyles = makeStyles(theme => ({
  nav: {
    marginLeft: theme.spacing(3),
    "& a": {
      display: 'block',
    }
  },
  footerLink: {
    color: theme.palette.text.secondary,
    fontSize: 18,
  },
}))

const Footer = ({ menuItems }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            borderRight={mobile ? "none" : "2px solid #dddddd"}
            pr={4}
            pb={4}
          >
            <GreyLogo />
            <Box mt={2} mb={2}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.footerText}
                paragraph
              >
                We are actively developing a platform that makes SGMA
                compliance, reporting, and managing water resources data easier.
                Sound like what you need? Drop us a line.
              </Typography>
              <RequestEarlyAccess />
            </Box>
          </Box>
        </Grid>
        <Grid item hidden={mobile}>
          <nav className={classes.nav}>
            {menuItems.map(item => (
              <Link key={item.id} to={item.path} className={classes.footerLink}>
                {item.title}
              </Link>
            ))}
          </nav>
        </Grid>
      </Grid>
      <Box
        mt={mobile ? 0 : 6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <a
          href="https://lrewater.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: 16 }}
        >
          <LRELogo />
        </a>
        <Typography align="center" variant="body1" color="textSecondary">
          © {new Date().getFullYear()} GSConfluence
        </Typography>
      </Box>
    </div>
  )
}

Footer.propTypes = {
  /**
   * An array of navigation menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      activePath: PropTypes.string.isRequired,
      exact: PropTypes.boolean,
    })
  ).isRequired,
}

export default Footer
